<template></template>

<script setup lang="ts">
type _VTI_TYPE_Column = {
  name: string;
  fieldInfo: FieldInfoInterface;
}
interface ColumnComponentProps {
  column: _VTI_TYPE_Column;
}
  import { computed } from 'vue'
  import { FilterMatchMode } from 'primevue/api'
  import { defineColumnFilter, defineColumnProps } from '../../lib/defines'
  
  const props = defineProps<ColumnComponentProps>();
  defineExpose(
    Object.assign(
      {},
      defineColumnProps({
        sortable: false,
        filterField: computed(() => props.column.name),
        showAddButton: false,
        showApplyButton: false,
        showClearButton: false,
        showFilterOperator: false,
        showFilterMatchModes: false,
      }),
      defineColumnFilter({
        matchMode: FilterMatchMode.IN,
      }),
    ),
  );
</script>

